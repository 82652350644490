.custom-btn {
    text-transform: uppercase;
    font-size: 17px;
    text-shadow: none;
    user-select: none;
    box-shadow: transparent;
  }
  
  .custom-btn:hover,
  .custom-btn:focus {
    text-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff, 0 0 20px #ffffff;
    box-shadow: 0 0 5px #008cff, 0 0 20px #008cff, 0 0 50px #008cff, 0 0 100px #008cff;
    /* box-shadow: 0 0 5px #ff1515, 0 0 20px #ff0000, 0 0 50px #ff0000, 0 0 100px #ff0000; */
  }
  